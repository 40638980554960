'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import HtzLink from '@haaretz/s-htz-link';
import TextLink from '@haaretz/s-text-link';
import useBi from '@haaretz/s-use-bi';
import { useRouter } from 'next/router';
import s9 from 'style9';

import type { BiDataOverrides } from '@haaretz/s-data-structure-types';

const c = s9.create({
  base: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginTop: space(9),

    ...merge(
      mq({ from: 'xl', until: 'xxl', value: { marginTop: space(8) } }),
      mq({ from: 'xxl', value: { marginTop: space(4) } })
    ),
  },
  textLink: {
    marginTop: space(4),
    marginBottom: space(11.25),

    ...merge(
      mq({ until: 's', value: { marginBottom: space(12.25) } }),
      mq({ from: 'xl', value: { marginBottom: space(19) } }),
      mq({ from: 'xl', until: 'xxl', value: { marginTop: space(5) } }),
      mq({ from: 'xxl', value: { marginTop: space(6), ...typesetter(-1) } })
    ),
  },
});

const STUDENTS_AND_SOLDIERS_PATH_SEGMENT = 'student-hdc';

interface OfferLinksProps {
  /** Bi data passed as an argument to biAction */
  biData?: BiDataOverrides;
}

export default function OfferLinks({ biData }: OfferLinksProps) {
  const router = useRouter();
  const biAction = useBi();
  const isStudentAndSoldierOffers = router.query.pathSegment === STUDENTS_AND_SOLDIERS_PATH_SEGMENT;

  return (
    <div className={s9(c.base)}>
      {isStudentAndSoldierOffers ? (
        <Button
          as={HtzLink}
          href="/offers"
          styleExtend={[c.button]}
          variant="neutral"
          data-testid="back-to-subscription-options-link"
        >
          Back to subscription options
        </Button>
      ) : (
        <Button
          as={HtzLink}
          href={`/${STUDENTS_AND_SOLDIERS_PATH_SEGMENT}/offers`}
          styleExtend={[c.button]}
          variant="neutral"
        >
          See student plans
        </Button>
      )}

      <TextLink
        styleExtend={[c.textLink]}
        href="https://www.lndit.co/6016aca579821.htm"
        data-testid="offer-organization-link"
        onClick={() => {
          biAction({
            ...biData,
            action_id: 50,
            feature: 'Promotions footer',
            feature_type: 'Marketing',
          });
        }}
      >
        Info about group plans
      </TextLink>
    </div>
  );
}
