/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import RichText from '@haaretz/s-rich-text';
import isHtmlNode from '@haaretz/s-type-predicates/isHtmlNode';
import * as React from 'react';
import s9 from 'style9';

import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';

const c = s9.create({
  base: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: space(8),
    textAlign: 'center',

    ...merge(mq({ until: 's', value: { marginBottom: space(5) } })),
  },
  marginByLines: {
    marginBottom: space(8),

    ...mq({ from: 'xxl', value: { marginBottom: space(8) } }),
  },
  line1: {
    color: color('primary1000'),
    ...typesetter(5),

    ...merge(
      mq({ until: 's', value: { ...typesetter(4) } }),
      mq({ from: 'xl', value: { ...typesetter(4) } })
    ),
  },
  line2: {
    ...typesetter(1),

    ...merge(
      mq({ until: 's', value: { ...typesetter(0) } }),
      mq({ from: 'xl', value: { ...typesetter(0) } })
    ),
  },
  strongColor: {
    color: color('primary1000'),
  },
});

export interface SubscriptionDetailsProps {
  line1: PurchaseOfferFragment['line1Text'];
  line2: PurchaseOfferFragment['line2Text'];
}

export default function SubscriptionDetails({ line1: [line1], line2 }: SubscriptionDetailsProps) {
  if (!isHtmlNode(line1)) return null;

  const { content: line1Content } = line1;
  const line2Content = line2?.[0] && isHtmlNode(line2[0]) ? line2[0].content : null;

  return (
    <div className={s9(c.base, !!line2Content && c.marginByLines)}>
      <RichText as="span" content={line1Content} styleExtend={[c.line1]} />
      {line2Content && (
        <RichText
          as="span"
          content={line2Content}
          styleExtend={[c.line2]}
          strongStyleExtend={[c.strongColor]}
        />
      )}
    </div>
  );
}
