/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import Circles from './Circles';

import type { PageDataFragment } from '@haaretz/s-fragments/PageData';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',

    ...mq({ until: 'l', value: { maxWidth: `calc(100vw - ${space(12)})` } }),
  },
  bullet: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: space(1),

    ...typesetter(1),

    ...merge(
      // fontSize and lineHeight
      mq({ from: 'xl', value: { ...typesetter(0) } }),

      // maxWidth
      mq({ until: 's', value: { maxWidth: space(62) } }),
      mq({ from: 's', until: 'l', value: { maxWidth: space(66.75) } }),
      mq({ from: 'l', until: 'xl', value: { maxWidth: space(78.75) } }),
      mq({ from: 'xl', until: 'xxl', value: { maxWidth: space(73.5) } }),
      mq({ from: 'xxl', value: { maxWidth: space(88) } })
    ),
  },
  text: {
    ...mq({ until: 's', value: { ...typesetter(0) } }),
  },
  bulletWrapper: {
    display: 'flex',
    textAlign: 'center',
    columnGap: space(4),
    position: 'relative',

    ...merge(
      mq({ until: 'xl', value: { flexDirection: 'column', alignItems: 'center' } }),

      mq({
        until: 's',
        value: {
          rowGap: space(7),
          paddingTop: space(1),
          paddingLeft: space(8),
          paddingBottom: space(7),
          paddingRight: space(7.75),
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          rowGap: space(7.75),
          paddingTop: space(5.25),
          paddingLeft: space(12.25),
          paddingBottom: space(5.25),
          paddingRight: space(12),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          rowGap: space(6),
          paddingTop: space(9),
          paddingLeft: space(12.75),
          paddingBottom: space(11),
          paddingRight: space(10.75),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          paddingTop: space(20.5),
          paddingLeft: space(13),
          paddingBottom: space(20.5),
          paddingRight: space(12.75),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          columnGap: space(5),
          paddingTop: space(22),
          paddingLeft: space(15.75),
          paddingBottom: space(30),
          paddingRight: space(16),
        },
      })
    ),
  },
});

export interface MarketingBulletsProps {
  marketingBullets: PageDataFragment['marketingBullets'];
}

export default function MarketingBullets({ marketingBullets }: MarketingBulletsProps) {
  if (!marketingBullets || !marketingBullets.length) return null;

  return (
    <section className={s9(c.base)} data-testid="marketing-bullets">
      <div className={s9(c.bulletWrapper)}>
        <Circles />
        {marketingBullets.map(({ title, text }, i) => (
          <div key={i} className={s9(c.bullet)} data-testid="marketing-bullet">
            <h3>{title}</h3>
            <p className={s9(c.text)}>{text}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
