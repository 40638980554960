/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  circlesWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: zIndex('below'),
  },
  circle: {
    position: 'absolute',
    borderRadius: radius('circle'),
    opacity: 0.15,

    ...mq({ until: 's', value: { opacity: 0.2 } }),
  },
  yellowCircle: {
    backgroundColor: color('tertiary600'),
  },
  yellowCircleOne: {
    ...merge(
      mq({
        until: 's',
        value: {
          width: space(7),
          height: space(7),
          top: 0,
          left: space(8),
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          width: space(5.75),
          height: space(5.75),
          top: 0,
          left: space(11.5),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          width: space(5.75),
          height: space(5.75),
          top: 0,
          left: space(11.5),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(7.25),
          height: space(7.25),
          top: space(0.5),
          left: space(17.5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(8.75),
          height: space(8.75),
          top: space(0.5),
          left: space(21),
        },
      })
    ),
  },
  yellowCircleTwo: {
    ...merge(
      mq({
        until: 's',
        value: {
          width: space(17),
          height: space(17),
          bottom: space(13),
          right: space(6.5),
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          width: space(19.75),
          height: space(19.75),
          bottom: space(15),
          right: space(7.75),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          width: space(22.25),
          height: space(22.25),
          bottom: 0,
          right: space(8.75),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(11.5),
          height: space(11.5),
          top: space(6),
          left: space(77.5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(14),
          height: space(14),
          top: space(6.25),
          left: space(93.25),
        },
      })
    ),
  },
  yellowCircleThree: {
    ...merge(
      mq({
        until: 's',
        value: {
          width: space(7),
          height: space(7),
          bottom: 0,
          right: space(20.25),
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          width: space(8),
          height: space(8),
          bottom: 0,
          right: space(23.5),
        },
      }),
      mq({ from: 'l', until: 'xl', value: { display: 'none' } }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(20.5),
          height: space(20.5),
          top: 0,
          right: space(14.5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(24.75),
          height: space(24.75),
          top: 0,
          right: space(17.5),
        },
      })
    ),
  },
  yellowCircleFour: {
    ...merge(
      mq({ until: 'xl', value: { display: 'none' } }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(9.75),
          height: space(9.75),
          bottom: 0,
          left: space(7.5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(12),
          height: space(12),
          bottom: 0,
          left: space(9),
        },
      })
    ),
  },
  yellowCircleFive: {
    ...merge(
      mq({ until: 'xl', value: { display: 'none' } }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(11.5),
          height: space(11.5),
          bottom: space(16.75),
          right: space(29.5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(14),
          height: space(14),
          bottom: space(20.25),
          right: space(35.25),
        },
      })
    ),
  },
  blueCircle: {
    backgroundColor: color('primary800'),
  },
  blueCircleOne: {
    ...merge(
      mq({
        until: 's',
        value: {
          width: space(6.25),
          height: space(6.25),
          top: 0,
          right: 0,
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          width: space(7.25),
          height: space(7.25),
          top: 0,
          right: 0,
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          width: space(8.25),
          height: space(8.25),
          top: 0,
          right: 0,
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(5),
          height: space(5),
          top: space(23),
          left: 0,
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(6),
          height: space(6),
          top: space(27.75),
          left: 0,
        },
      })
    ),
  },
  blueCircleTwo: {
    ...merge(
      mq({
        until: 's',
        value: {
          width: space(12.5),
          height: space(12.5),
          top: space(19.5),
          left: 0,
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          width: space(14.75),
          height: space(14.75),
          top: space(22.75),
          left: 0,
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          width: space(16.5),
          height: space(16.5),
          top: space(25.75),
          left: 0,
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(15),
          height: space(15),
          bottom: space(4.5),
          left: space(39.25),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(18),
          height: space(18),
          bottom: space(5.5),
          left: space(47.25),
        },
      })
    ),
  },
  blueCircleThree: {
    ...merge(
      mq({
        until: 's',
        value: {
          width: space(8.5),
          height: space(8.5),
          bottom: space(25.5),
          left: space(15.25),
        },
      }),
      mq({
        from: 's',
        until: 'l',
        value: {
          width: space(10.25),
          height: space(10.25),
          bottom: space(29.75),
          left: space(17.75),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          width: space(11.5),
          height: space(11.5),
          bottom: space(16.75),
          left: space(20),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(4.75),
          height: space(4.75),
          bottom: space(20.5),
          right: space(103.5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(5.75),
          height: space(5.75),
          bottom: space(24.75),
          right: space(124.5),
        },
      })
    ),
  },
  blueCircleFour: {
    ...merge(
      mq({ until: 'xl', value: { display: 'none' } }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(14),
          height: space(14),
          bottom: space(3),
          right: space(73.75),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(16.75),
          height: space(16.75),
          bottom: space(3.75),
          right: space(93.75),
        },
      })
    ),
  },
  blueCircleFive: {
    ...merge(
      mq({ until: 'xl', value: { display: 'none' } }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(5.25),
          height: space(5.25),
          bottom: space(14),
          right: 0,
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(6.25),
          height: space(6.25),
          bottom: space(17),
          right: 0,
        },
      })
    ),
  },
});

export interface MarketingBulletsProps {
  marketingBullets: PageDataFragment['marketingBullets'];
}

type CircleProps = {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
};

function Circle({ styleExtend = [], inlineStyle }: CircleProps) {
  return <div className={s9(c.circle, ...styleExtend)} style={inlineStyle} />;
}

export default function Circles() {
  return (
    <div className={s9(c.circlesWrapper)}>
      {/* Ordered from top to bottom, left to right */}
      <Circle styleExtend={[c.yellowCircle, c.yellowCircleOne]} />
      <Circle styleExtend={[c.yellowCircle, c.yellowCircleTwo]} />
      <Circle styleExtend={[c.yellowCircle, c.yellowCircleThree]} />
      <Circle styleExtend={[c.yellowCircle, c.yellowCircleFour]} />
      <Circle styleExtend={[c.yellowCircle, c.yellowCircleFive]} />
      <Circle styleExtend={[c.blueCircle, c.blueCircleOne]} />
      <Circle styleExtend={[c.blueCircle, c.blueCircleTwo]} />
      <Circle styleExtend={[c.blueCircle, c.blueCircleThree]} />
      <Circle styleExtend={[c.blueCircle, c.blueCircleFour]} />
      <Circle styleExtend={[c.blueCircle, c.blueCircleFive]} />
    </div>
  );
}
