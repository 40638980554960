'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useUser from '@haaretz/s-atoms/user';
import Card from '@haaretz/s-card';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import usePpOfferCardOperations from '@haaretz/s-use-pp-offer-card-operations';
import Link from 'next/link';
import * as React from 'react';
import s9 from 'style9';

import CancellableLine from './CancellableLine';
import OfferButton from './OfferButton';
import OfferTitle from './OfferTitle';
import SubscriptionDetails from './SubscriptionDetails';

import type { BiDataOverrides, RequiredSelectedOfferProps } from '@haaretz/s-data-structure-types';
import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { PurchaseOfferFragment } from '@haaretz/s-fragments/PurchaseOffer';
import type { StepDataFragment } from '@haaretz/s-fragments/StepData';

const widthBiggerThanXXL = space(119.25);
const widthRestMq = space(99.5);

const c = s9.create({
  base: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'initial',
    width: widthRestMq,
    paddingTop: space(12),
    paddingBottom: space(12),

    ...merge(
      // paddingBlock
      mq({
        until: 's',
        value: { paddingTop: space(7), paddingBottom: space(7) },
      }),
      mq({ from: 'xl', until: 'xxl', value: { paddingTop: space(13), paddingBottom: space(13) } }),
      mq({ from: 'xxl', value: { paddingTop: space(15), paddingBottom: space(15) } }),

      // paddingInline
      mq({ until: 'm', value: { paddingInlineStart: space(6), paddingInlineEnd: space(6) } }),
      mq({ from: 'm', value: { paddingInlineStart: space(11), paddingInlineEnd: space(11) } }),

      // width
      mq({ until: 's', value: { width: `min(${space(87.75)}, calc(100vw - ${space(6)}))` } }),
      mq({ from: 'xxl', value: { width: widthBiggerThanXXL } })
    ),
  },
  offerHighlight: {
    position: 'absolute',
    top: 0,
    paddingTop: space(1),
    paddingBottom: space(1),
    paddingRight: space(5),
    paddingLeft: space(5),
    borderRadius: radius('xxLarge'),
    backgroundColor: color('primary1000'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: zIndex('above'),
    fontWeight: 700,
    color: color('neutral100'),
    transform: 'translateY(-50%)',
    ...typesetter(-1),

    ...merge(
      mq({
        until: 's',
        value: {
          ...typesetter(-2),
          paddingTop: space(0.5),
          paddingBottom: space(0.5),
          paddingRight: space(4),
          paddingLeft: space(4),
        },
      }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
          paddingTop: space(1.5),
          paddingBottom: space(1.5),
          paddingRight: space(6),
          paddingLeft: space(6),
        },
      })
    ),
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

export interface OffersCardProps {
  exclusive?: PurchaseOfferFragment['exclusive'];
  productName: PurchaseOfferFragment['productName'];
  offerHighlight?: PurchaseOfferFragment['offerHighlight'];
  legalText: PurchaseOfferFragment['legalText'];
  line1Text: PurchaseOfferFragment['line1Text'];
  line2Text?: PurchaseOfferFragment['line2Text'];
  note?: PurchaseOfferFragment['note'];
  offerData: RequiredSelectedOfferProps;
  offerType: PurchaseOfferFragment['offerType'];
  steps: StepDataFragment[];
  couponId?: PageDataFragment['couponId'];
  personalCouponId?: PageDataFragment['personalCouponId'];
  /** Bi data passed as an argument to biAction */
  biData?: BiDataOverrides;
}

const sentImpressions: string[] = [];

export default function OfferCard({
  offerHighlight,
  productName,
  legalText,
  line1Text,
  line2Text,
  note,
  offerData,
  offerType,
  steps,
  biData,
  couponId,
  personalCouponId,
}: OffersCardProps) {
  const user = useUser('cookieValue');
  const offerCardRef = React.useRef<HTMLAnchorElement>(null);

  const onClickAndImpressionBiData: BiDataOverrides = {
    ...biData,
    prod_no: offerData.productNumber,
    sale_code: offerData.saleCode,
    promotions_no: offerData.promotionNumber,
    campaign_details: `title: ${productName}`,
  };

  const { asPath, enabledPpQuery, onClick } = usePpOfferCardOperations({
    offerData,
    onClickAndImpressionBiData: {
      ...onClickAndImpressionBiData,
      action_id: 21,
    },
    steps,
    couponId,
    personalCouponId,
    offerType,
  });

  const impressionKey = offerData.contentId + (user?.userMail || '');

  useImpressionObserver({
    disabled: sentImpressions.includes(impressionKey),
    elementRef: offerCardRef,
    biData: onClickAndImpressionBiData,
    onObserve: () => {
      sentImpressions.push(impressionKey);
    },
  });

  return (
    <Card elevationLevel={1} styleExtend={[c.base]}>
      {offerHighlight && <div className={s9(c.offerHighlight)}>{offerHighlight}</div>}
      <OfferTitle productName={productName} />
      <SubscriptionDetails
        line1={line1Text}
        line2={
          offerType === 'upgrade' && note
            ? [{ as: 'p', __typename: 'HtmlNode', content: note }]
            : line2Text
        }
      />
      <OfferButton onClick={onClick} state={enabledPpQuery ? 'busy' : undefined} />
      <CancellableLine
        legalText={legalText}
        biData={biData}
        onTermsButtonClick={onClick}
        loading={enabledPpQuery}
      />
      <Link
        aria-hidden
        tabIndex={-1}
        className={s9(c.link)}
        href={asPath}
        shallow
        onClick={onClick}
        ref={offerCardRef}
      >
        {''}
      </Link>
    </Card>
  );
}
