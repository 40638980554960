'use client';

import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Button, { ButtonProps } from '@haaretz/s-button';
import s9 from 'style9';

const c = s9.create({
  base: {
    marginBottom: space(3),
    marginTop: 'auto',
    zIndex: zIndex('above'),

    ...mq({ from: 'xxl', value: { marginBottom: space(4) } }),
  },
});

export interface OfferButtonProps {
  onClick?: ButtonProps<'button'>['onClick'];
  state?: ButtonProps<'button'>['state'];
}

export default function OfferButton({ onClick, state }: OfferButtonProps) {
  return (
    <Button
      data-testid="offer-btn"
      variant="sales"
      styleExtend={[c.base]}
      onClick={onClick}
      {...(state === 'busy' ? { state, busyNotice: '' } : { state })}
    >
      SUBSCRIBE NOW
    </Button>
  );
}
