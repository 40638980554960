'use client';

import color from '@haaretz/l-color.macro';
import fontStack from '@haaretz/l-font-stack.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import AssitanceModal from '@haaretz/s-assistance-modal';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import HtzImage from '@haaretz/s-htz-image';
import Notification from '@haaretz/s-notification';
import useAssistanceNotification from '@haaretz/s-pp-assistance-utils/useAssistanceNotification';
import PPCoupon from '@haaretz/s-pp-coupon';
import { usePurchasePagePersonalQuery } from '@haaretz/s-pp-queries/PurchasePage';
import getCurrentStep from '@haaretz/s-pp-routing-utils/getCurrentStep';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import usePpDataVariables from '@haaretz/s-use-pp-data-variables';
import { usePPOffsetStepsStoreActions } from '@haaretz/s-use-pp-offset-steps-store';
import { useRouter } from 'next/router';
import React from 'react';
import s9 from 'style9';

import MarketingBullets from './components/MarketingBullets';
import PpOfferCard from './components/OfferCard';
import OfferLinks from './components/OfferLinks';

import type { StepDataFragment } from '@haaretz/s-fragments/StepData';
import type { ppQueryVariables } from '@haaretz/s-use-pp-data-variables';

const widthMobile = 327;
const widthFromMedUntilXXL = 398;
const widthXXL = 477;

const c = s9.create({
  base: {
    fontFamily: fontStack('secondary'),
  },
  headerAndOffersWrapper: {
    display: 'flex',
    width: 'max-content',
    marginTop: space(13),
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',

    ...merge(
      mq({ until: 'l', value: { maxWidth: `calc(100vw - ${space(6)})` } }),
      mq({ until: 's', value: { marginTop: space(2) } }),
      mq({ from: 's', until: 'l', value: { marginTop: space(7.75) } }),
      mq({ from: 'l', until: 'xl', value: { marginTop: space(11.75) } })
    ),
  },
  header: {
    display: 'flex',
    columnGap: space(5),

    ...merge(
      mq({ until: 'l', value: { flexDirection: 'column', rowGap: space(4) } }),

      mq({ until: 's', value: { marginBottom: space(6.5) } }),
      mq({ from: 's', until: 'l', value: { marginBottom: space(8) } }),
      mq({ from: 'l', until: 'xl', value: { marginBottom: space(11) } }),
      mq({ from: 'xl', until: 'xxl', value: { marginBottom: space(12) } }),
      mq({ from: 'xxl', value: { marginBottom: space(13) } })
    ),
  },
  flexCenter: {
    justifyContent: 'center',
  },
  flexSpaceBetween: {
    justifyContent: 'space-between',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: space(widthFromMedUntilXXL / 4),

    ...merge(
      mq({ until: 's', value: { maxWidth: space(widthMobile / 4) } }),
      mq({ from: 'xxl', value: { maxWidth: space(widthXXL / 4) } }),
      mq({ until: 'l', value: { textAlign: 'center' } })
    ),
  },
  title: {
    color: color('neutral1200'),
    fontWeight: 400,
    marginBottom: space(2),
    fontFamily: fontStack('primary'),
    ...typesetter(5),

    ...merge(
      mq({ until: 's', value: { ...typesetter(5, { lines: 9 }) } }),
      mq({ from: 'xl', value: { ...typesetter(4) } })
    ),
  },
  subTitle: {
    fontWeight: 400,
    ...typesetter(1),

    ...merge({
      ...mq({ until: 's', value: { ...typesetter(0) } }),
      ...mq({ from: 'xl', value: { ...typesetter(0) } }),
    }),
  },
  image: {
    ...merge(
      mq({ until: 's', value: { marginBottom: space(1), marginTop: space(1) } }),
      mq({ from: 's', until: 'xxl', value: { width: space(widthFromMedUntilXXL / 4) } }),
      mq({ from: 'xxl', value: { width: space(widthXXL / 4) } })
    ),
  },
  offers: {
    display: 'flex',
    justifyContent: 'var(--justify-content-offers)',
    marginBottom: space(8),
    columnGap: space(6),
    ...merge(
      mq({ until: 's', value: { marginBottom: space(6) } }),
      mq({ from: 's', until: 'l', value: { marginBottom: space(7) } }),
      mq({ from: 'xxl', value: { marginBottom: space(10) } })
    ),

    ...mq({
      until: 'l',
      value: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: space(4),
      },
    }),
  },
  gapWithOfferhiglight: {
    ...mq({
      until: 'l',
      value: {
        rowGap: space(6.5),
      },
    }),
  },
  coupon: {
    ...merge(
      mq({ until: 'l', value: { marginBottom: space(9) } }),
      mq({ from: 'l', until: 'xl', value: { marginBottom: space(4) } }),
      mq({ from: 'xl', value: { marginBottom: space(3) } })
    ),
  },
  specialOffersWrapper: {
    backgroundImage: `radial-gradient(circle at 0 0, ${color('ppSpecialOfferBgGStart')}, ${color(
      'ppSpecialOfferBgGEnd'
    )})` as string,
    marginTop: space(-30),
    marginBottom: space(-123),
    paddingTop: space(25),
    paddingBottom: space(123),
    zIndex: '-1',
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          marginBottom: space(-141),
          paddingBottom: space(141),
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: { marginBottom: space(-114), paddingBottom: space(114) },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: { marginBottom: space(-118), paddingBottom: space(118) },
      }),
      mq({ from: 'xxl', value: { marginBottom: space(-135), paddingBottom: space(135) } })
    ),
  },
  specialOffersWrapperLoggedIn: {
    marginTop: space(-40),
    paddingTop: space(35),
  },
  specialSaleColor: {
    color: color('primary1000'),
  },
  specialTitle: {
    ...typesetter(-1),
    marginBottom: space(1),
    fontWeight: 700,

    ...merge(
      // typesetter
      mq({ until: 's', value: { ...typesetter(-2) } }),
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2) } }),
      mq({ from: 'xxl', value: { ...typesetter(-2) } }),

      // margin
      mq({ until: 's', value: { marginBottom: space(3) } }),
      mq({ until: 'l', value: { marginBottom: space(2) } })
    ),
  },
  notifText: {
    fontWeight: 700,

    ...typesetter(-2),

    ...mq({ from: 'm', value: { ...typesetter(-1) } }),
  },
  notifBtn: {
    ...typesetter(-2),
  },
});

const currentStepName: StepDataFragment['name'] = 'offers';
const notificationText = 'Want help purchasing a subscription?';

export default function OffersPageHdc(): JSX.Element | null {
  const excludedVariables: (keyof Required<ppQueryVariables>)[] = React.useMemo(
    () => ['selectedOfferId'],
    []
  );

  const variables = usePpDataVariables(excludedVariables);

  const { data } = usePurchasePagePersonalQuery(variables);
  const { clearSteps, pushStep } = usePPOffsetStepsStoreActions();
  const router = useRouter();
  const user = useUser('cookieValue');
  const notificationRef = React.useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const biAction = useBi();
  const assistanceModalRef = React.useRef<HTMLDialogElement>(null);
  const platform = usePlatform();
  const isDesktop = platform === 'desktop';

  const pageData = data?.PurchasePage?.pageData;

  useImpressionObserver({
    elementRef: notificationRef,
    biData: {
      feature: 'Promotions help',
      feature_type: 'Marketing',
      campaign_name: 'help tool shown',
      campaign_details: notificationText,
    },
  });

  useImpressionObserver({
    elementRef: assistanceModalRef,
    biData: {
      feature: 'Promotions help',
      feature_type: 'Marketing',
      campaign_name: 'help tool form shown',
    },
  });

  React.useEffect(() => {
    clearSteps();
  }, [clearSteps]);

  React.useEffect(() => {
    const routeChangeStartHandler = () => {
      pushStep(currentStepName);
    };

    router.events.on('routeChangeStart', routeChangeStartHandler);

    return () => {
      router.events.off('routeChangeStart', routeChangeStartHandler);
    };
  }, [pushStep, router.events, user?.isLoggedIn]);

  const { showAssistanceNotification, setShowAssistanceNotification } = useAssistanceNotification();

  if (!pageData) return null;

  const { steps, monthly, annual, couponId, personalCouponId, marketingBullets, specialSaleData } =
    pageData;

  const hasCoupon = couponId || personalCouponId;
  const image = specialSaleData?.image || pageData.image;

  const { currentStep, currentStepIndex } = getCurrentStep(currentStepName, steps);

  const offers = [...(annual ? annual : []), ...(monthly ? monthly : [])];

  const biData = {
    stage: 'offers',
    additional_info: {
      stage_number: (currentStepIndex + 1).toString(),
    },
  };

  return (
    <>
      {specialSaleData && (
        <div
          className={s9(c.specialOffersWrapper, user?.isLoggedIn && c.specialOffersWrapperLoggedIn)}
          data-testid="special-offer-section"
        />
      )}
      <div className={s9(c.base)}>
        <div className={s9(c.headerAndOffersWrapper)}>
          <header className={s9(c.header, image ? c.flexSpaceBetween : c.flexCenter)}>
            <div className={s9(c.titleWrapper)}>
              {specialSaleData && <h2 className={s9(c.specialTitle)}>{specialSaleData.title}</h2>}
              <h1
                className={s9(c.title, specialSaleData && c.specialSaleColor)}
                data-testid="pp-title"
              >
                {isDesktop ? currentStep.title : currentStep.mobileTitle}
              </h1>
              <h3
                className={s9(c.subTitle)}
                id="pp-page-instructions"
                data-testid="pp-instructions"
              >
                {isDesktop ? currentStep.instructions : currentStep.mobileInstructions}
              </h3>
            </div>
            {image && isDesktop && (
              <HtzImage
                {...image}
                styleExtend={[c.image]}
                imgData={image.files[0]}
                aspect="full"
                alt={image.alt}
                contentId={image.contentId}
                type={image?.type}
                credit={image.credit}
                widths={[327, 398, 477]}
                sizes={[
                  { from: 's', size: `${widthFromMedUntilXXL}px` },
                  { from: 'xxl', size: `${widthXXL}px` },
                  { size: `${widthMobile}px` },
                ]}
              />
            )}
          </header>

          <section
            className={s9(
              c.offers,
              offers.length > 1 ? c.flexSpaceBetween : c.flexCenter,
              !!offers[1]?.offerHighlight && c.gapWithOfferhiglight
            )}
          >
            {offers.length
              ? offers.map(offer => (
                  <PpOfferCard
                    key={offer.contentId}
                    {...offer}
                    offerData={offer}
                    steps={steps}
                    couponId={pageData.couponId}
                    personalCouponId={pageData.personalCouponId}
                    biData={{
                      ...biData,
                      ...(currentStep.messageIds ? { campaign_name: currentStep.messageIds } : {}),
                    }}
                  />
                ))
              : null}
          </section>
        </div>
        <PPCoupon
          defaultComponentToShow={hasCoupon ? 'success' : 'textfield'}
          biData={biData}
          styleExtend={[c.coupon]}
        />
        <MarketingBullets marketingBullets={marketingBullets} />
        <OfferLinks biData={biData} />
        <Notification
          variant="sales"
          priority="low"
          placement="top"
          onClose={() => setShowAssistanceNotification(false)}
          isOpen={showAssistanceNotification}
          ref={notificationRef}
        >
          <span className={s9(c.notifText)} data-testid="user-assist-notif">
            {notificationText}
          </span>
          <Button
            priority="tertiary"
            styleExtend={[c.notifBtn]}
            size="small"
            onClick={() => {
              setIsModalOpen(true);
              setShowAssistanceNotification(false);
              biAction({
                action_id: 184,
                feature: 'Promotions help',
                feature_type: 'Marketing',
                campaign_name: 'help tool clicked',
                campaign_details: notificationText,
              });
            }}
            data-testid="notification-approve-btn"
          >
            Yes
          </Button>
        </Notification>
        <AssitanceModal isOpen={isModalOpen} onToggle={setIsModalOpen} ref={assistanceModalRef} />
      </div>
    </>
  );
}
