import color from '@haaretz/l-color.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import * as React from 'react';
import s9 from 'style9';

import type { PageDataFragment } from '@haaretz/s-fragments/PageData';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  circlesWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: zIndex('below'),
  },
  circle: {
    position: 'absolute',
    borderRadius: radius('circle'),
    aspectRatio: '1',
    opacity: 0.3,
  },
  yellowButBlueForMobile: {
    backgroundColor: color('primary800'),

    ...mq({ from: 's', value: { backgroundColor: color('tertiary600') } }),
  },
  blueButYellowForMobile: {
    backgroundColor: color('tertiary600'),

    ...mq({ from: 's', value: { backgroundColor: color('primary800') } }),
  },
  circleOneRight: {
    width: space(10),
    top: '148px',
    right: '31px',

    ...mq({
      from: 's',
      value: {
        width: space(13.5),
        top: '132px',
        right: '108px',
      },
    }),
  },
  circleTwoRight: {
    width: space(13),
    bottom: '66px',
    right: '45px',

    ...mq({
      from: 's',
      value: {
        width: space(18),
        bottom: '143px',
        right: '114px',
      },
    }),
  },
  circleOneLeft: {
    width: space(6.5),
    top: '52px',
    left: '112px',

    ...mq({
      from: 's',
      value: {
        width: space(6.5),
        top: '115px',
        left: '179px',
      },
    }),
  },
  circleTwoLeft: {
    width: space(4.5),
    top: '188px',
    left: '31px',

    ...mq({
      from: 's',
      value: {
        width: space(6),
        top: '194px',
        left: '107px',
      },
    }),
  },
  circleThreeLeft: {
    width: space(8.5),
    bottom: '101px',
    left: '95px',

    ...mq({
      from: 's',
      value: {
        width: space(11.5),
        bottom: '150px',
        left: '156px',
      },
    }),
  },
});

export interface MarketingBulletsProps {
  marketingBullets: PageDataFragment['marketingBullets'];
}

type CircleProps = {
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
};

function Circle({ styleExtend = [], inlineStyle }: CircleProps) {
  return <div className={s9(c.circle, ...styleExtend)} style={inlineStyle} />;
}

export default function Circles() {
  return (
    <div className={s9(c.circlesWrapper)}>
      {/* Ordered from top to bottom */}
      <Circle styleExtend={[c.yellowButBlueForMobile, c.circleOneRight]} />
      <Circle styleExtend={[c.yellowButBlueForMobile, c.circleTwoRight]} />
      <Circle styleExtend={[c.blueButYellowForMobile, c.circleOneLeft]} />
      <Circle styleExtend={[c.blueButYellowForMobile, c.circleTwoLeft]} />
      <Circle styleExtend={[c.blueButYellowForMobile, c.circleThreeLeft]} />
    </div>
  );
}
